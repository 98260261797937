import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import createAppThunk from '../thunk';
import { get, post } from '../../authentication/axiosInterceptor';
import { Application, ApplicationsResponse, PreferencesResponse, UserPreference } from '../../types/response';
import { ApplicationState } from '../../types/state';
import { numbers } from '../../constants/dthConstants';
import { shortFormMap } from '../../types/common';

export const initialState: ApplicationState = {
  query: '',
  recentMaxCount: 5,
  apps: [],
  recents: [],
  filteredRecents: [],
  filteredApps: [],
  applicationClicked: 'dth',
};

const filterApps = (apps: Application[], query: string) => {
  return apps.filter(app => 
    app.label.toLowerCase().includes(query) || 
    (shortFormMap[query] && app.label.toLowerCase() === shortFormMap[query].toLowerCase())
  );
};

export const applicationSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  reducers: {
    loadAllApplcations(state, action: PayloadAction<{ apps: Application[]; recents: Application[] }>) {
      const { apps, recents } = action.payload;
      state.apps = apps;
      state.filteredApps = apps;
      state.recents = recents;
      state.filteredRecents = recents;
    },
    updateSearchQuery(state, action: PayloadAction<string>) {
      state.query = action.payload.toLowerCase();
    },
    setApplicationClicked(state, action: PayloadAction<string>) {
      state.applicationClicked = action.payload;
    },
    filterApplications(state) {
      const query = state.query.toLowerCase();
      state.filteredApps = filterApps(state.apps, query);
      state.filteredRecents = filterApps(state.recents, query);
    },
    handleRecents(state, action: PayloadAction<Application>) {
      const index = state.recents.findIndex(app => app.key === action.payload.key);
      if (index >= 0) {
        const [app] = state.recents.splice(index, 1);
        state.recents.unshift(app);
      }
      if (index < 0) state.recents.unshift(action.payload);
    },
  },
});

const fetchApplications = createAppThunk('applications/fetchApplications', async (_request: void, { dispatch }) => {
  try {
    const [appResponse, recentsResponse] = await Promise.all([
      get<ApplicationsResponse>('/getApps.json', { baseUrl: '' }),
      get<PreferencesResponse>('/api/v1/users?action=getPreference'),
    ]);
    const apps = appResponse?.data?.allApps || [];
    const recentsKeys =
      ((JSON.parse(recentsResponse?.data?.data?.[numbers.ZERO]?.userPreference || '{}') as UserPreference)
        ?.LastAccessedApps || []).filter(item => !!item);
    const recents = recentsKeys.map(key => apps.find(app => app.key === key)!);
    dispatch(applicationsActions.loadAllApplcations({ apps, recents }));
  } catch (error) {
    console.log('error', error); //NOSONAR
  }
});

const postRecents = createAppThunk('applications/postRecents', async (_request: void, { getState }) => {
  try {
    const recents = getState().apps.recents;
    await post('/api/v1/users?action=savePreference', { LastAccessedApps: recents.map(app => app.key) });
  } catch (error) {
    console.log(error); //NOSONAR
  }
});

export const applicationsReducer = applicationSlice.reducer;
export const applicationsActions = { ...applicationSlice.actions, fetchApplications, postRecents };
export default applicationsReducer;
